import {
  Page,
  Question,
  getHealthHistoryURL,
  getNextPage,
} from "../../utils/healthHistoryV2";
import {
  AddressInfo,
  HdyhauSurvey,
  HealthContextFields,
  Test,
} from "../../types/test";
import QuestionV2 from "./questions/question";
import { Form, Formik } from "formik";
import FormFooter from "./questions/formFooter";
import SectionIntroCard from "./sectionIntroCard";
import classNames from "classnames";
import {
  PRIVACY_POLICY,
  TELEHEALTH_INFORMED_CONSENT,
  TERMS_OF_SERVICE,
} from "../../pages/constants";
import { useNavigate } from "react-router-dom";
import Verify from "../../pages/tests/healthHistory/screens/verify";
import PersonalInformationForm from "./forms/personalInformationForm";
import TakeSampleConfirm from "../../pages/tests/healthHistory/screens/takeSampleConfirm";
import { useEffect, useState } from "react";
import Start from "../../pages/tests/healthHistory/screens/start";
import HealthProfileStart from "../../pages/tests/healthHistory/screens/startHealthProfile";
import EducationalMomentCard from "./educationalMoment";
import QuestionTitle from "./questions/questionTitle";
import {
  HEALTH_CONTEXT_SECTIONS,
  HEALTH_PROFILE_SECTIONS,
  INTRO_SECTIONS,
  getHealthHistorySection,
} from "./header";
import { getIsMobile } from "../../utils/general";
import Done from "../../pages/tests/healthHistory/screens/done";
import { prefillAnswer } from "../../utils/answerUtils";
import { ReactComponent as AlertTriangle } from "../common/icons/alert-triangle.svg";
import { sendViewedHealthHistoryPage } from "../../utils/analytics/customEventTracking";

const PageWrapper = ({
  page,
  questions,
  healthContext,
  submitPage,
  createHealthContext,
  test,
  fetchTest,
  fetchHealthContext,
  ldtEnabled,
  recordConsent,
  addressInfo,
  updateAddressInfo,
  hdyhauSurvey,
  submitHdyhauSurvey,
}: {
  page: Page;
  questions: Question[];
  healthContext: HealthContextFields;
  fetchTest: any;
  fetchHealthContext: any;
  submitPage: ({
    data,
    extraDataItems,
    relatedDiagnoses,
    onError,
  }: {
    data: any;
    extraDataItems: any;
    relatedDiagnoses: any;
    onError: any;
  }) => void;
  createHealthContext: (
    hash?: string,
    success?: () => void,
    failure?: () => void
  ) => void;
  test: Test;
  ldtEnabled: boolean;
  recordConsent: (
    hash?: string,
    success?: () => void,
    failure?: () => void
  ) => void;
  addressInfo: AddressInfo;
  updateAddressInfo: any;
  hdyhauSurvey?: HdyhauSurvey;
  submitHdyhauSurvey?: any;
}) => {
  const navigate = useNavigate();
  const completedSample = healthContext && healthContext.sample_taken_at;
  const submittedHealthHistory =
    healthContext && healthContext.health_history_submitted_at;

  const [selectedRelatedDiagnoses, setSelectedRelatedDiagnoses] = useState([]);
  const [
    currentCheckedOptionsForMultidimensional,
    setCurrentCheckedOptionsForPage,
  ] = useState([]);
  const [isLastPage, setIsLastPage] = useState(false);

  const [screenSizeBelowMD, setScreenSizeBelowMD] = useState(
    window.innerWidth < 768
  );

  useEffect(() => {
    // refetch test on the first screen after filling out Address page in case their expan eligibility has changed based on state
    if (page.slug === "symptoms") {
      fetchTest(test.hash);
    }

    // if the user navigates to a page via URL and the health history is not loaded yet
    // fall-back case, should not happen
    if (!healthContext && !INTRO_SECTIONS.includes(page.section)) {
      fetchHealthContext(test.hash);
    }
  }, [page]);

  useEffect(() => {
    const currentHHSection = getHealthHistorySection(page.section);
    sendViewedHealthHistoryPage({
      section: currentHHSection,
      page: page.slug,
      questionGroup: page.section,
      testHash: test.hash,
      version: 2,
      isSymptomatic:
        healthContext?.all_additional_symptoms &&
        healthContext?.all_additional_symptoms.length > 0,
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenSizeBelowMD(getIsMobile());
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const nextPage = getNextPage({
      currentPage: page,
      healthContextValues: healthContext,
      test,
      expanUpsellEnabled: test?.expan_upsell_enabled,
      ldtEnabled: ldtEnabled,
      hdyhauSurvey,
    });
    if (nextPage?.slug === "done") {
      setIsLastPage(true);
    } else {
      setIsLastPage(false);
    }
  }, [page]);

  // Depending on their current health history answers, we show different questions
  const getCurrentQuestions = (
    values: HealthContextFields,
    healthContext: any
  ) => {
    const filteredQuestions = questions.filter((question) => {
      return question.showQuestion({
        currentPageHealthContextValues: values,
        healthContextValues: healthContext,
        currentCheckedOptionsForMultidimensional,
      });
    });
    return filteredQuestions;
  };

  // Pre-populate with currently saved values on first render
  const getInitialValues = () => {
    // Sometimes the health context is not yet loaded on first render, so don't call get initial values yet
    if (!healthContext) {
      return {};
    }
    let initialValues: Record<string, any> = {};
    questions.forEach((question) => {
      // if there's custom pre-population logic, prioritize that
      if (question.getInitialValue) {
        if (
          question.answerType !== "multiselect_dimensional" &&
          question.answerType !== "multiselect_dimensional_short"
        ) {
          initialValues[question.slug] = question.getInitialValue({
            healthContextValues: healthContext,
            hdyhauSurvey,
          });
        } else {
          const multidimensionalValues = question.getInitialValue({
            healthContextValues: healthContext,
          });
          initialValues = { ...initialValues, ...multidimensionalValues };
        }
      } else {
        // if not, just try to grab the value from the current health history
        initialValues[question.slug] =
          healthContext[question.slug as keyof HealthContextFields];
      }
    });
    if (page.slug === "demographics") {
      const height = prefillAnswer({
        questionName: "demographics_height",
        healthContext: healthContext,
        defaultAns: undefined,
      });
      if (height) {
        initialValues["demographics_height_feet"] = Math.floor(
          Number(height) / 12
        );
        initialValues["demographics_height_inches"] = Number(height) % 12;
      }
    }
    return initialValues;
  };

  // Special validation for each question on the page:
  const handleValidate = (values: any) => {
    const errors: Record<string, boolean> = {};
    questions.forEach((question) => {
      if (
        question.showQuestion({
          currentPageHealthContextValues: values,
          healthContextValues: healthContext,
          currentCheckedOptionsForMultidimensional,
        })
      ) {
        const value = values[question.slug];
        if (question.handleValidate) {
          const error = question.handleValidate(
            currentCheckedOptionsForMultidimensional
              ? {
                  healthContextValues: values,
                  currentCheckedOptionsForMultidimensional,
                }
              : {
                  healthContextValues: values,
                }
          );
          if (error) {
            errors[question.slug] = error;
          }
        } else {
          // if there's no custom validation, just check if it's empty
          if (
            !question.optional &&
            (!value || value.length === 0 || value === "")
          ) {
            errors[question.slug] = true;
          }
        }
      }
    });
    return errors;
  };

  // Finally, update the health context
  const handleSubmit = (values: any) => {
    // For the first screen, if the health history doesn't exist we create it first
    if (page.slug === "verify" || page.slug === "intro") {
      const nextPage = getNextPage({
        currentPage: page,
        healthContextValues: healthContext,
        test,
        expanUpsellEnabled: test?.expan_upsell_enabled,
        ldtEnabled: ldtEnabled,
      });
      const url = getHealthHistoryURL(test.hash, nextPage?.slug);
      if (healthContext) {
        recordConsent(test.hash);
        navigate(url);
      } else {
        createHealthContext(test.hash, () => {
          recordConsent(test.hash);
          navigate(url);
        });
      }
    } else {
      let formattedData;
      // For some pages, we need to format the data before submitting
      // Examples: multiselect -> singleselect on frontend, related diagnosis, extra data
      if (page.formatPayload && values) {
        formattedData = page.formatPayload({
          currentPageHealthContextValues: values,
          updatedRelatedDiagnosesConditions: selectedRelatedDiagnoses,
        });
      }
      submitPage(
        formattedData
          ? formattedData
          : {
              data: values,
              onError: {},
            }
      );
    }
  };

  const showWarning =
    (completedSample && HEALTH_CONTEXT_SECTIONS.includes(page.section)) ||
    (submittedHealthHistory && HEALTH_PROFILE_SECTIONS.includes(page.section));

  return (
    <>
      {/* Show section intro if there is one defined for the current page */}
      {page.sectionIntro && (
        <SectionIntroCard
          healthContext={healthContext}
          sectionIntro={page.sectionIntro}
        />
      )}
      {showWarning && (
        <div className="flex mx-auto mb-2 w-full bg-coral p-2 px-3 rounded-md">
          <AlertTriangle className="stroke-evvy-black h-4 w-5 mr-1" />
          <div className="font-medium">
            These answers have been submitted. Any changes you make will not be
            saved.
          </div>
        </div>
      )}
      <div className="static">
        {/* Section title */}
        <div className="mt-[20px] sm:mt-[10px] text-center rounded-t-xl h-full p-5 bg-evvy-dark-beige">
          <p className="t3 sm:t2 semibold p-0 m-0">{page.section}</p>
        </div>
        {/* Address information is a separate form */}
        {page.slug === "personal-information" && (
          <div className={`mb-48 p-6 border rounded-b-xl bg-white`}>
            <PersonalInformationForm
              healthContext={healthContext}
              addressInfo={addressInfo}
              updateAddressInfo={updateAddressInfo}
              ldtEnabled={ldtEnabled}
              test={test}
            />
          </div>
        )}
        {page.slug === "sample-overview" && (
          <TakeSampleConfirm
            currentPage={page}
            test={test}
            healthContext={healthContext}
            ldtEnabled={ldtEnabled}
            fetchHealthContext={fetchHealthContext}
          />
        )}
        {page.slug === "done" && <Done test={test} />}
        {page.slug !== "personal-information" &&
          page.slug !== "sample-overview" && (
            <div>
              {/* Show educational moment if it's a show for everyone (no show condition) */}
              <Formik
                enableReinitialize={true}
                initialValues={getInitialValues()}
                validate={(values): any => {
                  return handleValidate(values);
                }}
                onSubmit={(values): any => {
                  return handleSubmit(values);
                }}
                validateOnChange={false}
                validateOnBlur={false}
                key={page.slug}
              >
                {({
                  values,
                  errors,
                  setFieldValue,
                  handleChange,
                  handleSubmit,
                }) => (
                  <Form>
                    <div
                      className={classNames(
                        `rounded-b-xl bg-white mb-24 md:mb-48`,
                        { "sm:p-[30px] p-[20px]": page.slug !== "done" }
                      )}
                    >
                      {!screenSizeBelowMD &&
                        page.educationalMoment &&
                        page.educationalMoment.alwaysShow && (
                          <EducationalMomentCard
                            isMobile={screenSizeBelowMD}
                            educationalMoment={page.educationalMoment}
                            healthContext={values as HealthContextFields}
                            page={page}
                            hash={test.hash}
                          />
                        )}
                      {/* Render static components for health history */}
                      {page.slug === "intro" && <Start />}
                      {page.slug === "verify" && <Verify />}
                      {page.slug === "health-profile" && (
                        <HealthProfileStart
                          isReturningUser={test.has_other_active_test}
                        />
                      )}
                      {page.slug === "treatment-response" && (
                        <QuestionTitle questionTitle="treatment_response" />
                      )}
                      {page.slug === "treatment-details" && (
                        <QuestionTitle questionTitle="treatments_brand_or_product" />
                      )}
                      {/* Core questions  */}
                      {getCurrentQuestions(
                        values as HealthContextFields,
                        healthContext
                      ).map((question, index) => {
                        return (
                          <div
                            className={classNames(
                              `border rounded-lg bg-white`,
                              {
                                "mt-6": index !== 0,
                                "border-coral p-4": errors[question.slug],
                                "pt-12 p-4 mt-[6px]":
                                  errors[question.slug] &&
                                  question.slug.includes("_other"),
                                "border-transparent": !errors[question.slug],
                              }
                            )}
                            data-tip={errors[question.slug]}
                          >
                            <QuestionV2
                              question={question}
                              values={values}
                              healthContext={healthContext}
                              setFieldValue={setFieldValue}
                              handleChange={handleChange}
                              setSelectedRelatedDiagnoses={
                                setSelectedRelatedDiagnoses
                              }
                              setCurrentCheckedOptionsForPage={
                                setCurrentCheckedOptionsForPage
                              }
                              hdyhauSurvey={hdyhauSurvey}
                            />
                          </div>
                        );
                      })}
                      {!screenSizeBelowMD &&
                        page.educationalMoment &&
                        page.educationalMoment.showEducationalMoment?.({
                          healthContextValues: values as HealthContextFields,
                        }) && (
                          <EducationalMomentCard
                            isMobile={screenSizeBelowMD}
                            educationalMoment={page.educationalMoment}
                            healthContext={values as HealthContextFields}
                            page={page}
                            hash={test.hash}
                          />
                        )}
                    </div>
                    {page.slug == "intro" && (
                      <div className="italic text-sm text-center mx-auto -mt-16 sm:-mt-36 mb-36 sm:mb-48">
                        <div className="md:w-7/12 mx-auto">
                          By clicking{" "}
                          <i>
                            <b>Next</b>
                          </i>
                          , you agree to our{" "}
                          <a
                            href={PRIVACY_POLICY}
                            target="_blank"
                            className="hover:underline persistSize"
                          >
                            privacy policy
                          </a>
                          ,{" "}
                          <a
                            href={TERMS_OF_SERVICE}
                            target="_blank"
                            className="hover:underline persistSize"
                          >
                            terms of service
                          </a>{" "}
                          and{" "}
                          <a
                            href={TELEHEALTH_INFORMED_CONSENT}
                            target="_blank"
                            className="hover:underline persistSize"
                          >
                            telehealth informed consent
                          </a>
                          .
                        </div>
                      </div>
                    )}
                    {screenSizeBelowMD && page.educationalMoment && (
                      <EducationalMomentCard
                        isMobile={screenSizeBelowMD}
                        educationalMoment={page.educationalMoment}
                        healthContext={values as HealthContextFields}
                        page={page}
                        hash={test.hash}
                      />
                    )}
                    {errors && Object.keys(errors).length > 0 && (
                      <div className="-mt-16 mb-36 sm:mb-48 md:-mt-[150px] bg-coral p-2 px-3 flex rounded-md">
                        <AlertTriangle className="stroke-evvy-black h-4 w-5 mr-1" />
                        <div className="font-medium">
                          {page.customErrorMessage
                            ? page.customErrorMessage({
                                healthContextValues: values,
                              })
                            : "Please answer all questions to continue"}
                        </div>
                      </div>
                    )}
                    {/* Address page has its own footer with some different logic */}
                    {page.slug !== "personal-information" && (
                      <FormFooter
                        handleSubmit={handleSubmit}
                        errors={errors}
                        isLastPage={isLastPage}
                        isDoneScreen={page.slug === "done"}
                      />
                    )}
                  </Form>
                )}
              </Formik>
              {/* Show educational moment at bottom if there is a show condition */}
            </div>
          )}
      </div>
    </>
  );
};

export default PageWrapper;
