import React, { useEffect, useState, useRef } from "react";

import { Formik, Field, Form } from "formik";
import ReactTooltip from "react-tooltip";
import { useNavigate } from "react-router-dom";

import FormFooter from "../questions/formFooter";
import { PROVIDER_DISCLAIMER_TEXT } from "../../../pages/constants";
import {
  sendPatientRespondedToProviderAuthorization,
  sendViewedHealthHistoryPage,
} from "../../../utils/analytics/customEventTracking";
import { AddressInfo, HealthContextFields } from "../../../types/test";
import { testsService } from "../../../services/tests";
import {
  getHealthHistoryURL,
  getNextPage,
} from "../../../utils/healthHistoryV2";
import Markdown from "markdown-to-jsx";
import { AddressSearchInput } from "../../care/consultIntake";

const PersonalInformationForm = ({
  test,
  addressInfo,
  updateAddressInfo,
  ldtEnabled,
  healthContext,
}: {
  test: any;
  addressInfo: AddressInfo;
  updateAddressInfo: any;
  ldtEnabled: boolean;
  healthContext: HealthContextFields;
}) => {
  const navigate = useNavigate();
  const hash = test.hash;
  const [error, setError] = useState(null);
  const [apiErrors, setApiErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const addressFirstLineQuestion = useRef(null);
  const addressSecondLineQuestion = useRef(null);
  const cityQuestion = useRef(null);
  const zipQuestion = useRef(null);
  const stateQuestion = useRef(null);
  const phoneNumberQuestion = useRef(null);

  const allQuestions = {
    address_first_line: addressFirstLineQuestion,
    address_second_line: addressSecondLineQuestion,
    city: cityQuestion,
    state_code: stateQuestion,
    zip_code: zipQuestion,
    phone_number: phoneNumberQuestion,
  };

  const submitCurrentPage = (data: any, onError: any) => {
    const { provider_release, ...rest } = data;
    const provider_release_decision = data.provider_release;
    if (test.is_provider_ordered) {
      testsService.authorizeReleaseToProvider(hash, provider_release_decision);
      sendPatientRespondedToProviderAuthorization({
        testHash: hash,
        testOrder: test.test_order,
        resultsReleasedToProvider: provider_release_decision,
      });
    }

    updateAddressInfo(
      rest,
      () => {
        setIsSubmitting(false);
        const nextPage = getNextPage({
          currentPage: {
            slug: "personal-information",
            section: "Address",
            showPage: () => true,
            questions: [],
          },
          healthContextValues: healthContext,
          test,
          ldtEnabled: ldtEnabled,
          expanUpsellEnabled: test?.expan_upsell_enabled,
        });
        const url = getHealthHistoryURL(hash, nextPage?.slug);
        navigate(url);
        window.scrollTo(0, 0);
      },
      (response: any) => {
        setIsSubmitting(false);
        if (onError) onError(response);
        console.log("error submitting", response);
      }
    );
    return;
  };

  const getError = (key: string, errors: any, apiErrors: any) => {
    if (errors[key]) {
      return errors[key];
    } else if (apiErrors[key] && apiErrors[key].length) {
      console.log("error is ", apiErrors[key][0]);
      return apiErrors[key][0];
    }
  };

  const getInitialValues = () => {
    return {
      address_first_line: addressInfo?.address_first_line || "",
      address_second_line: addressInfo?.address_second_line || "",
      city: addressInfo?.city || "",
      zip_code: addressInfo?.zip_code || "",
      state_code: addressInfo?.state_code || "",
      phone_number: addressInfo?.phone_number || "",
      provider_release: true,
    };
  };

  useEffect(() => {
    sendViewedHealthHistoryPage({
      section: "context",
      page: "personal-information",
      questionGroup: "Address",
      testOrder: test.test_order,
      testHash: test.hash,
      version: 2,
      isSymptomatic: undefined,
    });
  }, []);

  return (
    <div>
      <Formik
        initialValues={getInitialValues()}
        validate={(values) => {
          let errors: { [key: string]: string } = {};
          Object.keys(allQuestions).forEach((questionKey) => {
            const ref = allQuestions[questionKey as keyof typeof allQuestions];
            if (ref && ref.current) {
              ReactTooltip.hide(ref.current);
            }
          });

          var requiredQuestions = [
            "address_first_line",
            "city",
            "zip_code",
            "state_code",
            "phone_number",
          ];

          // required questions
          requiredQuestions.forEach((key) => {
            if (!values[key as keyof typeof values]) {
              errors[key] = "This is a required question";
              const ref = allQuestions[key as keyof typeof allQuestions];
              if (ref && ref.current) {
                ReactTooltip.show(ref.current);
              }
            }
          });
          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          setError(null);
          submitCurrentPage(values, (response: any) => {
            console.log("form got an error", response);
            if (typeof response === "object") {
              setApiErrors(response);
              Object.keys(response).forEach((key) => {
                const ref = allQuestions[key as keyof typeof allQuestions];
                if (ref && ref.current) {
                  ReactTooltip.show(ref.current);
                }
              });
            } else {
              setError(response || "Error saving personal information");
            }
          });
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
          <Form>
            <div>
              {error ? (
                <div className="bg-coral p-4 px-3 font-medium rounded-sm mt-6">
                  {error}
                </div>
              ) : (
                ""
              )}
              <div className="mb-6 b2">
                Please confirm your address so we can match you with a clinician
                who is in your state to approve your test order and review your
                results.
              </div>

              <ReactTooltip
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />

              <div
                className={`border rounded-md mb-4 p-2 ${
                  getError("address_first_line", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("address_first_line", errors, apiErrors)}
                data-for="address_first_line"
                ref={addressFirstLineQuestion}
              >
                <ReactTooltip
                  id="address_first_line"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <div className="t4 mb-2">Street address</div>
                {/* wait to make sure this works properly for care intake before updating here! */}
                <AddressSearchInput
                  className=""
                  setFormFieldValue={setFieldValue}
                  getInitialValues={getInitialValues}
                />
              </div>

              <div
                className={`border rounded-md mb-4 p-2 ${
                  getError("address_second_line", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("address_second_line", errors, apiErrors)}
                data-for="address_second_line"
                ref={addressSecondLineQuestion}
              >
                <ReactTooltip
                  id="address_second_line"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <div className="t4 mb-2">Apartment/Other</div>
                <Field
                  name="address_second_line"
                  as="input"
                  className="w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4"
                  autoComplete="off"
                />
              </div>
              <div className="grid grid-cols-4 gap-2">
                <div
                  className={`col-span-4 sm:col-span-2 border rounded-md mb-2 p-2 ${
                    getError("city", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("city", errors, apiErrors)}
                  data-for="city"
                  ref={cityQuestion}
                >
                  <ReactTooltip
                    id="city"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <div className="t4 mb-2">City</div>
                  <Field
                    name="city"
                    as="input"
                    className="w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4"
                    autoComplete="off"
                  />
                </div>
                <div
                  className={`col-span-4 sm:col-span-2 border rounded-md mb-2 p-2 ${
                    getError("state_code", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("state_code", errors, apiErrors)}
                  data-for="state_code"
                  ref={stateQuestion}
                >
                  <ReactTooltip
                    id="state_code"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <div className="t4 mb-2">State</div>
                  <Field
                    name="state_code"
                    as="input"
                    className="w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4"
                    autoComplete="off"
                  />
                </div>
                <div
                  className={`col-span-4 sm:col-span-2 border rounded-md mb-2 p-2 ${
                    getError("zip_code", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("zip_code", errors, apiErrors)}
                  data-for="zip_code"
                  ref={zipQuestion}
                >
                  <ReactTooltip
                    id="zip_code"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <div className="t4 mb-2">Zipcode</div>
                  <Field
                    name="zip_code"
                    as="input"
                    className="w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4"
                    autoComplete="off"
                  />
                </div>
                <div
                  className={`col-span-4 sm:col-span-2 border rounded-md mb-2 p-2 ${
                    getError("phone_number", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("phone_number", errors, apiErrors)}
                  data-for="phone_number"
                  ref={phoneNumberQuestion}
                >
                  <ReactTooltip
                    id="phone_number"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <div className="t4 mb-2">Phone Number</div>
                  <Field
                    name="phone_number"
                    as="input"
                    className="w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4"
                    autoComplete="off"
                  />
                </div>
                {test.is_provider_ordered && test.provider && (
                  <div className="mt-4 w-full col-span-4">
                    <div className="t4 mb-2">
                      Share Evvy info with your provider?
                    </div>
                    <div className="mb-2 w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4 bg-gray-200">{`${test.provider.first_name} ${test.provider.last_name}  (${test.provider.affiliated_practice})`}</div>
                    <div className="mb-4 w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4 bg-gray-200">
                      {test.provider.email}
                    </div>
                    <div className="flex items-center">
                      <Field
                        id="provider_release"
                        name="provider_release"
                        type="checkbox"
                        required
                        className="bg-evvy-cream rounded-sm border-evvy-blue cursor-pointer focus:outline-none focus:ring-evvy-blue active:ring-evvy-blue checked:bg-evvy-blue"
                      />
                      <label htmlFor="provider_release" className="ml-4 mb-0">
                        <Markdown
                          options={{
                            overrides: {
                              p: {
                                props: {
                                  className: "mb-0",
                                },
                              },
                            },
                          }}
                        >
                          {PROVIDER_DISCLAIMER_TEXT}
                        </Markdown>
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <FormFooter
              handleSubmit={handleSubmit}
              errors={errors}
              isSubmitting={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalInformationForm;
