import { useState, useEffect, useContext } from "react";
import {
  Routes,
  Route,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Consult, ConsultIntake } from "../../../../types/care";
import UngatedRxIntakeHeader from "../../../../components/care/consultIntake/ungatedRx/UngatedRxHeader";
import Consent from "./Consent";
import { careService } from "../../../../services/care";
import { AxiosResponse } from "axios";
import { ConsultsContext } from "../../../../contexts/consultsContext";
import Allergies from "./Allergies";
import Symptoms from "./Symptoms";
import BoricAcid from "../../../../components/care/consultIntake/ungatedRx/BoricAcid";
import ShippingQuestions from "../shipping";
import IdPhotoUpload from "../idPhotoUpload";
import Done from "./Done";
import OfframpModal from "../../../../components/care/consultIntake/ungatedRx/OfframpModal";
import Markdown from "markdown-to-jsx";
import LoadingSpinner from "../../../../components/common/loadingSpinner";
import IntakeUpsell from "../../../../components/care/consultIntake/upsells/SymptomsUpsell";
import { useConsultAdditionalRecommendedProducts } from "../../../../hooks/care/useConsultAdditionalRecommendedProducts";
import { useLoggedInUser } from "../../../../hooks/useLoggedInUser";

const UngatedRxConsultIntake = () => {
  const user = useLoggedInUser();
  const { consultId } = useParams();
  const { consults, refetchConsults } = useContext(ConsultsContext);
  const currentConsult = consults?.find((c) => c.uid === consultId);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // start out as loading so that page doesn't try to render without data loaded up first
  const [error, setError] = useState<string | null>(null);
  const [consultIntake, setConsultIntake] = useState<ConsultIntake>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [consultAlreadySubmitted, setConsultAlreadySubmitted] = useState(false);
  const [allowReupload, setAllowReupload] = useState(false);
  const [showExitOfframpModal, setShowExitOfframpModal] = useState(false);
  const [showInvalidStateOfframpModal, setShowInvalidStateOfframpModal] =
    useState(false);
  const [
    boricAcidRemovedBecauseOfAllergies,
    setBoricAcidRemovedBecauseOfAllergies,
  ] = useState(false);

  const baseUrl = `/care/rx/consults/${consultId}/intake/`;

  const { recommendedProducts, refetchConsultAdditionalProducts } =
    useConsultAdditionalRecommendedProducts(currentConsult?.uid);

  const queryParams = new URLSearchParams(window.location.search);
  const hasBoricAcidFromUpsell = queryParams.get("hasBoricAcid") === "true";

  const hasBoricAcidTreatment =
    currentConsult?.selected_treatments?.some(
      (treatment) => treatment.type === "boric_acid"
    ) || hasBoricAcidFromUpsell;

  const hasRecommendedProducts =
    (recommendedProducts?.ungatedRxTreatments &&
      recommendedProducts.ungatedRxTreatments.length > 0) ||
    recommendedProducts?.test;

  const ungatedRXUpsellEnabled = user.features.ungatedRxUpsell;

  var allPages = [
    "consent/",
    "allergies/",
    "symptoms/",
    ...(!consultAlreadySubmitted &&
    hasRecommendedProducts &&
    ungatedRXUpsellEnabled
      ? ["more-treatments/"]
      : []),
    ...(hasBoricAcidTreatment && !boricAcidRemovedBecauseOfAllergies
      ? ["boric-acid/"]
      : []),
    "shipping/",
    "identity-verification/photo/",
    "done/",
  ];

  if (allowReupload) {
    allPages = ["identity-verification/reupload/", "done/"];
  }

  const currentPage = pathname.split(baseUrl)[1];
  const pageIndex = allPages.indexOf(currentPage);
  const isValidPage = pageIndex !== -1;
  const isFirstPage = isValidPage ? pageIndex === 0 : false;
  var previousPage = isFirstPage ? "/care/" : allPages[pageIndex - 1];
  const isLastPage = isValidPage ? pageIndex === allPages.length - 1 : false;
  const nextPage = !isLastPage ? allPages[pageIndex + 1] : null;
  const isLastIntakeInputPage = nextPage === "done/"; // last page where you are inputting data (so /done/ page doesn't count)
  const idErrorReason = consultIntake?.consult?.error_reason_display;
  const idErrorDetails = consultIntake?.consult?.error_details;

  // For navigating users to the right page when continuing their consult intake after checking out on an upsell
  if (currentPage.includes("continue")) {
    if (hasBoricAcidFromUpsell) {
      navigate(`${baseUrl}boric-acid/`);
    } else {
      navigate(`${baseUrl}shipping/`);
    }
  }

  useEffect(() => {
    const getConsultIntake = async (consultId: string | undefined) => {
      setLoading(true);
      let consultIntake = null;

      const fetchConsultIntake = async (consultId: string | undefined) => {
        const consultResponse = await careService.fetchConsultIntake(
          consultId,
          (response: AxiosResponse) => {
            return response;
          }
        );

        return consultResponse ? consultResponse.data : null;
      };

      consultIntake = await fetchConsultIntake(consultId);

      setConsultIntake(consultIntake);

      if (consultIntake.submitted_at) {
        setConsultAlreadySubmitted(true);
        if (consultIntake.consult.status === "ID") {
          setAllowReupload(true);
        }
      }

      // If the user has not accepted the terms, and they are not already on the intro page
      // and have not submitted and are not ineligible, then redirect them to the intro page
      if (
        !consultIntake.ungated_rx_confirmation &&
        !isFirstPage &&
        !consultAlreadySubmitted &&
        currentConsult?.status !== "IE"
      ) {
        navigate(`${baseUrl}consent/`);
      }

      setLoading(false);

      return consultIntake;
    };

    getConsultIntake(consultId);
  }, [
    currentPage,
    consultId,
    isFirstPage,
    navigate,
    baseUrl,
    consultAlreadySubmitted,
    currentConsult?.status,
  ]);

  const getNextPageFullUrl = () => {
    // if next page is not a relative to the intake pages, then just use it directly
    if (nextPage && nextPage.startsWith("/")) {
      return nextPage;
    } else {
      return `${baseUrl}${nextPage}`;
    }
  };

  const submitCurrentPage = (data: any) => {
    // if user already submitted intake, then just continue to next page
    if (consultIntake?.submitted_at) {
      // if is last page, then there might be empty data fields, so just submit
      if (!consultIntake?.submitted_at && isLastIntakeInputPage) {
        submitConsultIntake();
        return;
      }
      if (nextPage) {
        navigate(getNextPageFullUrl());
      } else {
        navigate(`${baseUrl}done/`);
      }
      window.scrollTo(0, 0);
      return;
    }

    // otherwise if not already submitted, then update before continuing to next page
    updateConsultIntake(data, (responseData) => {
      // check if referred out
      // IE status means that they are ineligible for care, show them exit modal
      if (responseData.consult.status === "IE") {
        if (responseData.consult?.ineligible_reason === "state")
          setShowInvalidStateOfframpModal(true);
        else setShowExitOfframpModal(true);
        window.scrollTo(0, 0);
        // trigger refresh of consults state in context
        refetchConsults();
        return;
      }

      if (!isLastIntakeInputPage) {
        refetchConsultAdditionalProducts();
        navigate(getNextPageFullUrl());
        window.scrollTo(0, 0);
      } else {
        // last page yay!
        // submit consult intake
        submitConsultIntake();
      }
    });
  };

  const submitConsultIntake = () => {
    setIsSubmitting(true);
    careService.submitConsultIntake(
      consultId,
      (response: AxiosResponse) => {
        navigate(`${baseUrl}done/`);
        setIsSubmitting(false);
        window.scrollTo(0, 0);
        // trigger refresh of consults state in context
        refetchConsults();
      },
      (err: any, resp: string) => {
        console.error(err);
        setIsSubmitting(false);
      }
    );
  };

  // this is used when we just want to update consult without going to next page
  const updateConsultIntake = (
    data: any,
    onSuccess: (responseData: { consult: Consult }) => void
  ) => {
    setIsSubmitting(true);
    // if user already submitted intake or if no data
    if (consultIntake?.submitted_at && !allowReupload) {
      setIsSubmitting(false);
      navigate(`${baseUrl}done/`);
      return;
    }

    // otherwise if not already submitted, then update
    careService.updateConsultIntake(
      consultId,
      data,
      (response: AxiosResponse) => {
        setConsultIntake(response.data);
        if (onSuccess) {
          onSuccess(response.data);
        }
        setIsSubmitting(false);
      },
      (err: any, resp: string) => {
        console.error(err);
        setIsSubmitting(false);
        if (resp === "Consult intake already submitted") {
          // if somehow user navigated back and tries to resubmit
          navigate(`${baseUrl}done/`);
        }
      }
    );
  };

  // used by intro screen to accept terms and proceed to next page
  const proceedToNextPage = () => {
    // IE status means that they are ineligible for care, show them exit modal
    if (consultIntake?.consult.status === "IE") {
      if (consultIntake.consult?.ineligible_reason === "state")
        setShowInvalidStateOfframpModal(true);
      else setShowExitOfframpModal(true);
      window.scrollTo(0, 0);
      return;
    }
    navigate(`${baseUrl}${nextPage}`);
    window.scrollTo(0, 0);
  };

  // used by intro screen to accept terms and proceed to next page
  const proceedPastIntroPage = () => {
    if (
      !consultIntake?.submitted_at &&
      consultIntake?.consult.status !== "IE"
    ) {
      careService
        .asyncConsentToUngatedRxTerms(consultId)
        .then((response) => {
          proceedToNextPage();
        })
        .catch((error) => {
          console.error(error);
          setError("Error agreeing to terms");
        });
    } else {
      proceedToNextPage();
    }
  };

  useEffect(() => {
    if (consultIntake?.consult.status === "IE") {
      if (consultIntake.consult?.ineligible_reason === "state")
        setShowInvalidStateOfframpModal(true);
      else setShowExitOfframpModal(true);
    }
  }, [consultIntake]);

  if (!consultIntake) return null;

  if (loading) return <LoadingSpinner />;

  return (
    <>
      <div className="bg-evvy-cream min-h-screen pt-[120px] sm:pt-[88px]">
        <UngatedRxIntakeHeader
          previousPage={previousPage}
          baseUrl={baseUrl}
          hasBoricAcidTreatment={hasBoricAcidTreatment || false}
          allowReupload={allowReupload}
        />
        <div className="max-w-full sm:max-w-2xl py-4 mx-auto px-3 sm:px-0 w-full">
          {consultAlreadySubmitted &&
            currentPage !== "done/" &&
            !allowReupload && (
              <div className="flex">
                <div className="mx-auto bg-coral p-2 px-3 font-medium rounded-md mt-6">
                  These answers have been submitted.
                  <br />
                  Any changes you make will not be saved.
                </div>
              </div>
            )}
          {allowReupload &&
            currentPage === "identity-verification/reupload/" && (
              <div className="bg-red-200 p-3 text-center rounded-md mx-4">
                <div>
                  {`${idErrorReason ? `${idErrorReason}: ` : ""}${
                    idErrorDetails
                      ? `${idErrorDetails}`
                      : "There was a problem with your originally uploaded images."
                  }`}
                </div>
                <div>Please upload again to proceed.</div>
              </div>
            )}
          <Routes>
            <Route
              path="/consent/"
              element={
                <Consent
                  currentConsult={currentConsult}
                  consultIntake={consultIntake}
                  submitPage={updateConsultIntake}
                  agreeToTerms={proceedPastIntroPage}
                  isSubmitting={isSubmitting}
                />
              }
            />
            <Route
              path="/allergies/"
              element={
                <Allergies
                  currentConsult={currentConsult}
                  consultIntake={consultIntake}
                  submitPage={submitCurrentPage}
                  isSubmitting={isSubmitting}
                  setBoricAcidRemovedBecauseOfAllergies={
                    setBoricAcidRemovedBecauseOfAllergies
                  }
                />
              }
            />
            <Route
              path="/symptoms/"
              element={
                <Symptoms
                  consultIntake={consultIntake}
                  submitPage={submitCurrentPage}
                  isSubmitting={isSubmitting}
                />
              }
            />
            {
              <Route
                path="/more-treatments/"
                element={
                  <IntakeUpsell
                    getNextPageFullUrl={getNextPageFullUrl}
                    consultIntake={consultIntake}
                  />
                }
              />
            }
            {hasBoricAcidTreatment && (
              <Route
                path="/boric-acid/"
                element={
                  <BoricAcid
                    consultIntake={consultIntake}
                    submitPage={submitCurrentPage}
                    isSubmitting={isSubmitting}
                  />
                }
              />
            )}
            <Route
              path="/shipping/"
              element={
                <ShippingQuestions
                  consultIntake={consultIntake}
                  submitPage={submitCurrentPage}
                  loading={isSubmitting}
                  hideHeader={true}
                  useStickyFooter={true}
                />
              }
            />
            <Route
              path="/identity-verification/photo/"
              element={
                <IdPhotoUpload
                  consultIntake={consultIntake}
                  submitPage={submitCurrentPage}
                  updateConsultIntake={updateConsultIntake}
                  loading={isSubmitting}
                  redo={false}
                  hideHeader={true}
                  useStickyFooter={true}
                />
              }
            />
            <Route
              path="/identity-verification/reupload/"
              element={
                <IdPhotoUpload
                  consultIntake={consultIntake}
                  submitPage={submitConsultIntake}
                  updateConsultIntake={updateConsultIntake}
                  loading={isSubmitting}
                  redo={true}
                  hideHeader={true}
                  useStickyFooter={true}
                />
              }
            />
            <Route
              path="/done/"
              element={<Done consultIntake={consultIntake} />}
            />
          </Routes>
        </div>
      </div>
      {showExitOfframpModal && (
        <OfframpModal
          heading="Thank you"
          onConfirm={() => navigate("/tests")}
          onClose={() => setShowExitOfframpModal(false)}
          buttonOptions="single"
        >
          <Markdown className="leading-normal">
            Our team will be in touch to refund your order.
          </Markdown>
        </OfframpModal>
      )}
      {showInvalidStateOfframpModal && (
        <OfframpModal
          heading="Your state is not eligible for care"
          onConfirm={() => navigate("/")}
          onClose={() => setShowInvalidStateOfframpModal(false)}
          buttonOptions="single"
        >
          <Markdown className="leading-normal">
            Unfortunately, Evvy cannot (yet) offer care in your state. **Your
            cost of treatment will be completely refunded. Our team will be in
            touch to refund your order.**
          </Markdown>
        </OfframpModal>
      )}
    </>
  );
};

export default UngatedRxConsultIntake;
