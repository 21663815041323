import { useState, useEffect, useCallback } from "react";
import { ConsultRecommendedProducts } from "../../types/care";
import { careService } from "../../services/care";

export const useConsultAdditionalRecommendedProducts = (
  consultUid?: string
) => {
  const [data, setData] = useState<ConsultRecommendedProducts>();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);

  const fetchConsultAdditionalProducts = useCallback(async () => {
    if (consultUid) {
      setIsLoading(true);
      try {
        const response = await careService.fetchConsultAdditionalProducts(
          consultUid
        );
        setData({
          ungatedRxTreatments: response.data.ungated_rx_treatments,
          test: response.data.test,
        });
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [consultUid]);

  useEffect(() => {
    if (consultUid) fetchConsultAdditionalProducts();
  }, [consultUid, fetchConsultAdditionalProducts, refetch]);

  const refetchConsultAdditionalProducts = () => {
    setRefetch((prev) => !prev);
  };

  return {
    recommendedProducts: data,
    error,
    isLoading,
    refetchConsultAdditionalProducts,
  };
};
