import { useState } from "react";
import { ConsultIntake, Prescription } from "../../../../types/care";
import { StethoscopeIcon } from "../../icons/stethoscopeIcon";
import PageWrapper from "../ungatedRx/PageWrapper";
import { useNavigate } from "react-router-dom";
import { careService } from "../../../../services/care";
import { useConsultAdditionalRecommendedProducts } from "../../../../hooks/care/useConsultAdditionalRecommendedProducts";
import TestUpsellCard from "./TestUpsellCard";
import { indexCheckboxStyles } from "../../../../utils/colors";
import LoadingSpinner from "../../../common/loadingSpinner";
import ALaCareTreatmentCard from "../../aLaCare/ALaCareTreatmentCard";

const IntakeUpsell = ({
  consultIntake,
  getNextPageFullUrl,
}: {
  consultIntake: ConsultIntake;
  getNextPageFullUrl: () => string;
}) => {
  const navigate = useNavigate();
  const { recommendedProducts, isLoading } =
    useConsultAdditionalRecommendedProducts(consultIntake.consult.uid);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [addedProducts, setAddedProducts] = useState<Prescription[]>([]);
  const [addedTest, setAddedTest] = useState<string>("");

  // If they've already consented to not trying to conceive, we don't need to show the consent again
  const consentReceived =
    consultIntake.currently_trying_to_conceive &&
    consultIntake.currently_trying_to_conceive == "NO";

  const [
    productsRequiringConceiveConfirmation,
    setProductsRequiringConceiveConfirmation,
  ] = useState<Prescription[]>([]);

  const [hasSeenTryingToConceiveWarning, setHasSeenTryingToConceiveWarning] =
    useState<boolean>(false);

  const productAdded = (product: Prescription) => {
    return addedProducts.includes(product);
  };

  async function goToCheckout() {
    setLoading(true);
    try {
      // Update their consent if they've consented to the terms
      if (productsRequiringConceiveConfirmation.length > 0) {
        careService.asyncConsentToTryingNotToConceive(
          consultIntake.consult.uid
        );
      }
      const response = await careService.createConsultUpsellCheckoutUrl({
        consultUid: consultIntake.consult.uid,
        data: {
          treatments: addedProducts,
          test: addedTest,
          checkout_source: "ungated-rx-intake",
        },
      });
      const checkoutURL = response.data.checkout_url;
      window.location = checkoutURL;
    } catch (error) {
      console.error(error);
      setError("Error creating checkout URL");
    }
  }

  const handleCheckConceiveConfirmation = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.target.checked) {
      setProductsRequiringConceiveConfirmation([]);
      setAddedProducts(
        addedProducts.filter((product) => !product.do_not_take_if_pregnant)
      );
    }
  };

  const handleSubmit = () => {
    // If there are items in the cart, call the get checkout URL endpoint and navigate there
    if (addedProducts?.length > 0 || addedTest) {
      goToCheckout();
    } else {
      // Otherwise, navigate to the next page
      navigate(getNextPageFullUrl());
    }
  };

  const addProductToCart = (product: Prescription) => {
    if (!addedProducts.includes(product)) {
      if (product.do_not_take_if_pregnant) {
        if (!productsRequiringConceiveConfirmation.includes(product)) {
          setProductsRequiringConceiveConfirmation([
            ...productsRequiringConceiveConfirmation,
            product,
          ]);
          if (!hasSeenTryingToConceiveWarning && !consentReceived) {
            setHasSeenTryingToConceiveWarning(true);
            const scrollToElement = (elementId: string) => {
              const element = document.getElementById(elementId);
              if (element) {
                element.scrollIntoView({
                  behavior: "smooth",
                });
              }
            };
            scrollToElement("warning");
          }
        }
      }
      setAddedProducts([...addedProducts, product]);
    } else {
      if (product.do_not_take_if_pregnant) {
        const updatedProductsRequiringConceiveConfirmation =
          productsRequiringConceiveConfirmation.filter((p) => p !== product);
        setProductsRequiringConceiveConfirmation(
          updatedProductsRequiringConceiveConfirmation
        );
        if (updatedProductsRequiringConceiveConfirmation.length === 0) {
          setHasSeenTryingToConceiveWarning(false);
        }
      }
      setAddedProducts(addedProducts.filter((p) => p !== product));
    }
  };

  return (
    <div>
      <div
        className="p-8 rounded-t-lg mt-6"
        style={{ backgroundImage: `url('/images/graphics/upsell-header.png')` }}
      >
        <div className="p-[10px] bg-white rounded-lg flex ">
          <div className="flex items-center">
            <div className="mr-4">
              <StethoscopeIcon strokeClassName="white" />
            </div>
            <div>
              <p className="t4 mb-0">From our experts</p>
              <p className="mb-0 b3">
                {recommendedProducts?.ungatedRxTreatments &&
                recommendedProducts?.ungatedRxTreatments.length > 0
                  ? "Based on your current symptoms, we think these treatments might help."
                  : "Evvy's vaginal microbiome test can help you get to the root cause."}
              </p>
            </div>
          </div>
        </div>
      </div>
      <PageWrapper>
        {/* Product cards based on recommendations */}
        {isLoading ? (
          <div>
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            <div className="grid grid-cols-2 gap-4">
              {recommendedProducts?.ungatedRxTreatments?.map((product) => (
                <ALaCareTreatmentCard
                  treatment={product}
                  phase={product.purpose_tag || "relief"}
                  isUngatedRxProduct={true}
                  customAddToCart={addProductToCart}
                  inCart={productAdded(product)}
                />
              ))}
              {recommendedProducts?.test ? (
                <TestUpsellCard
                  added={addedTest}
                  setTestAdded={() =>
                    setAddedTest(addedTest === "VHT" ? "" : "VHT")
                  }
                />
              ) : null}
            </div>
            <div className="mt-4 italic text-[12px] text-evvy-black/50 hidden md:block mb-4">
              *Any prescriptions will be determined at the sole discretion of an
              Evvy-affiliated provider. Individual results for any given product
              may vary.
            </div>
          </div>
        )}
        {!consentReceived &&
          productsRequiringConceiveConfirmation?.length > 0 && (
            <div className="mt-4 flex align-start border-evvy-blue border-2 bg-evvy-blue-light p-4 rounded-lg cursor-pointer">
              <div className="mr-2">
                <input
                  type="checkbox"
                  name="notTryingToConceiveConfirmation"
                  id="notTryingToConceiveConfirmation"
                  checked={productsRequiringConceiveConfirmation?.length > 0}
                  onChange={handleCheckConceiveConfirmation}
                  className={indexCheckboxStyles(4)}
                />
              </div>
              <div>
                <p className={"medium b1 mb-1"}>
                  By continuing, I confirm that I am not trying to conceive.
                </p>
                <p className="medium text-xs italic mb-0 pb-0">
                  {productsRequiringConceiveConfirmation?.map(
                    (product, index) => {
                      if (index === 0) {
                        return product.title_short_display;
                      } else {
                        return "and " + product.title_short_display;
                      }
                    }
                  )}
                  {productsRequiringConceiveConfirmation?.length > 1
                    ? " are"
                    : " is"}{" "}
                  not safe to use if you are trying to conceive. Please un-check
                  this box if you are trying to conceive and we will remove this
                  from your cart.
                </p>
              </div>
            </div>
          )}
        <PageWrapper.FormFooter
          isSubmitting={false}
          handleSubmit={handleSubmit}
          buttonCTA={
            addedProducts.length > 0 || addedTest ? "Checkout" : "Next"
          }
          disabled={false}
        />
      </PageWrapper>
      <div id="warning" />
    </div>
  );
};

export default IntakeUpsell;
