import React from "react";
import { TEST_IMAGE } from "../../constants";
import { cn } from "../../../../utils/cn";
import { ReactComponent as Checkmark } from "../../../common/icons/checkmark.svg";

const TestUpsellCard = ({
  added,
  setTestAdded,
}: {
  added: string;
  setTestAdded: () => void;
}) => {
  return (
    <div className="bg-white border-evvy-black border-opacity-10 border rounded-2xl w-full max-w-[250px] sm:max-w-[285px] flex flex-col cursor-pointer">
      <div className="flex items-center justify-center rounded-2xl bg-gradient-to-b from-[#D8CFCA] to-white via-[#D8CFCA] via-75% h-[130px] sm:h-[200px] relative">
        <img src={TEST_IMAGE} alt={"Evvy Vaginal Health Test"} />
      </div>
      <div className="flex flex-col justify-between flex-grow">
        <div className="px-5 mb-5">
          <div className="text-lg font-medium leading-tight sm:leading-normal mb-1 sm:mb-0">
            Evvy Vaginal Health Test
          </div>
          <div className="text-sm text-gray-500">
            Uncover 700+ bacteria & fungi with one swab.
          </div>
        </div>
        <div className="px-5 pb-5">
          <button
            className={cn(
              "p-2.5 w-full flex items-center border rounded transition-colors duration-100",
              {
                "border-evvy-dark-beige": added,
                "border-evvy-black hover:bg-evvy-blue": !added,
              }
            )}
            onClick={(e) => {
              e.stopPropagation();
              setTestAdded();
            }}
          >
            {added && <Checkmark className="h-5 w-5 mr-2 flex-shrink-0" />}
            <div className="flex-grow flex flex-wrap justify-between items-center">
              <div className="t4">{added ? "Added" : "Add"}</div>
              <div className="b2 semibold content-stretch">$129</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestUpsellCard;
