import {
  postRequest,
  deleteRequest,
  getRequest,
  patchRequest,
  putRequest,
} from "../utils/axios";

function fetchConsults(onSuccess, onError) {
  return getRequest(
    "/api/v1/consults/",
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function createConsult(data, onSuccess, onError) {
  return postRequest(
    "/api/v1/consults/",
    data,
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

async function asyncCreateConsult(data) {
  const response = await postRequest(
    "/api/v1/consults/",
    data,
    (response) => {
      return response;
    },
    (error, response) => {
      console.error(error);
      throw error;
    }
  );
  return response;
}

function fetchTreatmentPlan(hash) {
  return getRequest(
    `/api/v1/consults/${hash}/treatment_plan/`,
    (response) => {
      return response;
    },
    (error) => {
      throw error;
    }
  );
}

function setTreatmentPlanStartDate(hash, data) {
  return postRequest(
    `/api/v1/consults/${hash}/treatment_start_date/`,
    data,
    (response) => {
      return response;
    },
    (error) => {
      throw error;
    }
  );
}

async function asyncFetchRecommendedTreatmentPlan(consultUid) {
  const response = await getRequest(
    `/api/v1/consults/${consultUid}/recommended-treatment-plan/`,
    (response) => {
      return response;
    },
    (error) => {
      throw error;
    }
  );
  return response;
}

async function createPeriod({ consultUid, startDate, days }) {
  const data = {
    consult_uid: consultUid,
    start_date: startDate,
    days,
  };
  const response = await postRequest(
    `/api/v1/treatment-interruption/`,
    data,
    (response) => {
      return response;
    },
    (error, response) => {
      console.error(error);
      throw error;
    }
  );
  return response;
}

async function updatePeriod({ id, startDate, days }) {
  const data = {
    start_date: startDate,
    days,
  };
  const response = await putRequest(
    `/api/v1/treatment-interruption/${id}/`,
    data,
    (response) => {
      return response;
    },
    (error, response) => {
      console.error(error);
      throw error;
    }
  );
  return response;
}

async function deletePeriod(id) {
  const response = await deleteRequest(
    `/api/v1/treatment-interruption/${id}/`,
    (response) => {
      return response;
    },
    (error, response) => {
      console.error(error);
      throw error;
    }
  );
  return response;
}

function fetchConsult(consultUid, onSuccess, onError) {
  return getRequest(
    `/api/v1/consults/${consultUid}/`,
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function fetchPrescriptions(consultUid) {
  return getRequest(
    `/api/v1/consults/${consultUid}/prescriptions/`,
    (response) => {
      return response;
    },
    (error) => {
      throw error;
    }
  );
}

function fetchConsultMessages(consultUid, limit, before, onSuccess, onError) {
  var url = `/api/v1/consults/${consultUid}/messages/`;
  if (!limit) {
    limit = 100;
  }
  url += `?limit=${limit}`;
  if (before) {
    url += `&before=${before}`;
  }
  return getRequest(
    url,
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function sendConsultMessage(consultUid, data, onSuccess, onError) {
  return postRequest(
    `/api/v1/consults/${consultUid}/messages/`,
    data,
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function fetchConsultCheckoutURL(consultUid, onSuccess, onError) {
  return getRequest(
    `/api/v1/consults/${consultUid}/checkout_url/`,
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

async function createConsultUpsellCheckoutUrl({ consultUid, data }) {
  const response = await postRequest(
    `/api/v1/consults/${consultUid}/cross-sell-checkout-url/`,
    data,
    (response) => {
      return response;
    },
    (error) => {
      throw error;
    }
  );
  return response;
}

async function fetchConsultAdditionalProducts(consultUid, onSuccess, onError) {
  return getRequest(
    `/api/v1/consults/${consultUid}/recommended-products/`,
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
      return response;
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function fetchRefillsCheckoutURL(consultUid, data, onSuccess, onError) {
  return postRequest(
    `/api/v1/consults/${consultUid}/refill_checkout_url/`,
    data,
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}
function fetchConsultIntake(consultUid, onSuccess, onError) {
  return getRequest(
    `/api/v1/consult-intake/${consultUid}/`,
    (response) => {
      if (onSuccess) {
        return onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        return onError(error, response);
      }
    }
  );
}

function createConsultIntake(data, onSuccess, onError) {
  return postRequest(
    "/api/v1/consult-intake/",
    data,
    (response) => {
      if (onSuccess) {
        return onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        return onError(error, response);
      }
    }
  );
}

async function asyncAgreeToTerms(consultUid) {
  const response = await postRequest(
    `/api/v1/consult-intake/${consultUid}/agree_to_terms/`,
    {},
    (response) => {
      return response;
    },
    (error) => {
      throw error;
    }
  );
  return response;
}

async function asyncConsentToUngatedRxTerms(consultUid) {
  const response = await postRequest(
    `/api/v1/consult-intake/${consultUid}/consent_to_ungated_rx_terms/`,
    {},
    (response) => {
      return response;
    },
    (error) => {
      throw error;
    }
  );
  return response;
}

async function asyncConsentToTryingNotToConceive(consultUid) {
  const response = await postRequest(
    `/api/v1/consult-intake/${consultUid}/consent_to_not_trying_to_conceive/`,
    {},
    (response) => {
      return response;
    },
    (error) => {
      throw error;
    }
  );
  return response;
}

async function asyncSelectRemoveAntibiotics(consultUid, removeAntibiotics) {
  const response = await postRequest(
    `/api/v1/consult-intake/${consultUid}/select_remove_antibiotics/`,
    {
      remove_antibiotics_selected: removeAntibiotics,
    },
    (response) => {
      return response;
    },
    (error) => {
      throw error;
    }
  );
  return response;
}

async function updateConsultTreatmentPathway(consultUid, selectedPathwaySlug) {
  const response = await patchRequest(
    `/api/v1/consults/${consultUid}/`,
    { treatment_pathway_slug: selectedPathwaySlug },
    (response) => {
      return response;
    },
    (error) => {
      throw error;
    }
  );
  return response;
}

async function updateConsultPurchaseType(consultUid, purchaseType) {
  const response = await putRequest(
    `/api/v1/consults/${consultUid}/purchase-type/`,
    { purchase_type: purchaseType },
    (response) => {
      return response;
    },
    (error) => {
      throw error;
    }
  );
  return response;
}

function updateConsultIntake(consultUid, data, onSuccess, onError) {
  return patchRequest(
    `/api/v1/consult-intake/${consultUid}/`,
    data,
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function submitConsultIntake(consultUid, onSuccess, onError) {
  return postRequest(
    `/api/v1/consult-intake/${consultUid}/submit/`,
    {},
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function fetchMedications(onSuccess, onError) {
  return getRequest(
    `/api/v1/consult-intake/medications/`,
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

async function asyncSearchPharmacies(postalCode, pharmacyName) {
  let queryParams = [];
  if (postalCode) {
    queryParams.push(`postal_code=${postalCode}`);
  }

  if (pharmacyName) {
    queryParams.push(`pharmacy_name=${pharmacyName}`);
  }
  const url = `/api/v1/consult-intake/pharmacies/?${queryParams.join("&")}`;

  const response = await getRequest(
    url,
    (response) => {
      return response;
    },
    (error) => {
      throw error;
    }
  );
  return response;
}

async function asyncFetchTreatmentAdjustmentReasons(adjustmentType) {
  const response = await getRequest(
    `/api/v1/calendar-treatments/adjustment-reason-options/?adjustment_type=${adjustmentType}`,
    (response) => {
      return response;
    },
    (error) => {
      throw error;
    }
  );
  return response;
}

async function asyncUpdateCalendarTreatment(treatmentId, data) {
  const response = await patchRequest(
    `/api/v1/calendar-treatments/${treatmentId}/`,
    data,
    (response) => {
      return response;
    },
    (error) => {
      throw error;
    }
  );
  return response;
}

async function resetCalendarToDefault(consultUid) {
  const response = await patchRequest(
    `/api/v1/consults/${consultUid}/reset-calendar/`,
    {},
    (response) => {
      return response;
    },
    (error) => {
      throw error;
    }
  );
  return response;
}

async function asyncFetchTreatmentProductInfo(treatmentSlug) {
  const response = await getRequest(
    `/api/v1/products/${treatmentSlug}/`,
    (response) => {
      return response;
    },
    (error) => {
      throw error;
    }
  );
  return response;
}

const fetchFAQs = async (page) => {
  const response = await getRequest(
    `/api/v1/faqs/?page=${page}`,
    (response) => {
      return response;
    },
    (error) => {
      throw error;
    }
  );
  return response;
};

async function asyncFetchConsultAddressInfo(consultUid) {
  const response = await getRequest(
    `/api/v1/consult-intake/${consultUid}/address-info/`,
    (response) => {
      return response;
    },
    (error) => {
      throw error;
    }
  );
  return response;
}

export const careService = {
  asyncCreateConsult,
  fetchConsults,
  fetchConsult,
  createConsult,
  fetchConsultCheckoutURL,
  fetchPrescriptions,
  fetchRefillsCheckoutURL,
  fetchTreatmentPlan,
  fetchMedications,
  setTreatmentPlanStartDate,
  fetchConsultIntake,
  createConsultIntake,
  updateConsultIntake,
  submitConsultIntake,
  fetchConsultMessages,
  sendConsultMessage,
  createPeriod,
  updatePeriod,
  deletePeriod,
  asyncAgreeToTerms,
  asyncConsentToUngatedRxTerms,
  asyncConsentToTryingNotToConceive,
  asyncSelectRemoveAntibiotics,
  updateConsultTreatmentPathway,
  updateConsultPurchaseType,
  asyncFetchRecommendedTreatmentPlan,
  asyncSearchPharmacies,
  asyncFetchTreatmentAdjustmentReasons,
  asyncUpdateCalendarTreatment,
  resetCalendarToDefault,
  asyncFetchTreatmentProductInfo,
  createConsultUpsellCheckoutUrl,
  fetchConsultAdditionalProducts,
  asyncFetchConsultAddressInfo,
  fetchFAQs,
};
