import React, { useState } from "react";
import { resendOrderTransferEmail } from "../../services/order";
import { ReactComponent as AlertTriangle } from "../../components/common/icons/alert-triangle.svg";
import { Order } from "../../types/orders";

const VerifyAccountOrderTransfer = ({ orders }: { orders?: Order[] }) => {
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState<string>("");

  const unattachedOrderNumbers = orders
    ?.filter((order) => order.order_number)
    .map((order) => order.order_number);

  const unattachedOrderNumberString = unattachedOrderNumbers
    ?.map((orderNumber) => "#" + orderNumber)
    ?.join(", ");

  const resendEmail = () => {
    if (!unattachedOrderNumbers || unattachedOrderNumbers.length === 0) return;
    setSending(true);
    resendOrderTransferEmail(
      unattachedOrderNumbers,
      () => {
        setSending(false);
        setSent(true);
      },
      () => {
        setError("Failed to resend email");
      }
    );
  };

  return (
    <div className="bg-highlighter-salmon/70 rounded-2xl p-4 mb-4 text-evvy-black">
      <div className="flex items-center mb-3">
        <AlertTriangle className="w-5 h-5 mr-1" />
        <span className="text-sm font-semibold leading-none uppercase">
          Attention
        </span>
      </div>
      <div className="text-sm font-medium">
        Your care order
        {unattachedOrderNumbers && unattachedOrderNumbers?.length > 1
          ? "s"
          : ""}{" "}
        ({unattachedOrderNumberString}){" "}
        {unattachedOrderNumbers && unattachedOrderNumbers?.length > 1
          ? "have"
          : "has"}{" "}
        not been linked to this account. To receive your treatment, please check
        the email you used to place your order.
        <br />
        {sending ? (
          <div className="mt-2">Sending...</div>
        ) : sent ? (
          <div className="mt-2">Check your email to confirm.</div>
        ) : error ? (
          <div className="mt-2">{error}</div>
        ) : (
          <div
            className="mt-2 cursor-pointer"
            onClick={() => {
              setSending(true);
              resendEmail();
            }}
          >
            <span className="underline persistSize"> Click here to resend</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyAccountOrderTransfer;
