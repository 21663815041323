import React from "react";
import { Link } from "react-router-dom";
import { getParameterByName } from "../../utils/urls";
import { useEffect, useState } from "react";
import LayoutWelcome from "../../components/welcome/layout";
import { verifyAccountOrderTransferConfirm } from "../../services/order";

const OrderTransferredConfirmation = () => {
  const hash = getParameterByName("hash");
  const [confirmed, setConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [evvyEmail, setEvvyEmail] = useState<string>("");
  const [orderEmail, setOrderEmail] = useState<string>("");
  const [unattachedOrderNumberString, setUnattachedOrderNumberString] =
    useState<string>("");

  useEffect(() => {
    transferOrderToAccount();
  }, []);

  const transferOrderToAccount = () => {
    if (loading) return;
    setLoading(true);
    verifyAccountOrderTransferConfirm(
      hash,
      (response: any) => {
        const transferredOrders = response.data.orders;
        const unattachedOrderNumbers = Array.isArray(transferredOrders)
          ? transferredOrders
              ?.filter((order: any) => order.order_number)
              .map((order: any) => order.order_number)
          : [];
        const unattachedOrderNumberString = unattachedOrderNumbers
          ?.map((orderNumber: string) => "#" + orderNumber)
          ?.join(", ");
        setUnattachedOrderNumberString(unattachedOrderNumberString);
        setEvvyEmail(response.data.evvy_email);
        setOrderEmail(response.data.order_email);
        setConfirmed(true);
        setLoading(false);
      },
      (response: any) => {
        setLoading(false);
        if (response?.data?.error) {
          setError(response.data.error);
        } else {
          setError("Failed to confirm order transfer");
        }
      }
    );
  };

  return (
    <LayoutWelcome
      metaTitle="Order transfer confirmed"
      title={""}
      subtitle={"Welcome to Evvy"}
      smallTopMargin={true}
    >
      <div className="mb-8 text-center">
        {loading && !error ? (
          <div>Please wait...</div>
        ) : error ? (
          <div>{error}</div>
        ) : confirmed ? (
          <div>
            <div>
              {unattachedOrderNumberString ? (
                <span className="tracking-wider">
                  Your care order
                  {unattachedOrderNumberString &&
                  unattachedOrderNumberString?.includes(",")
                    ? "s"
                    : ""}{" "}
                  ({unattachedOrderNumberString}){" "}
                  {unattachedOrderNumberString &&
                  unattachedOrderNumberString?.includes(",")
                    ? "have"
                    : "has"}{" "}
                  been successfully transferred from {orderEmail} to {evvyEmail}
                  .
                </span>
              ) : (
                // Fall back on this message if we don't have order numbers
                <span className="tracking-wider">
                  Your care order has been successfully transferred.
                </span>
              )}
            </div>
            <div className="mt-8">
              <img
                className="h-40 mx-auto"
                src="/images/graphics/triple-welcome.png"
                loading="lazy"
                alt="Evvy Science"
              />
            </div>
            <div className="mt-8">
              <Link to="/">
                <button className="cursor-pointer w-full sm:w-auto justify-center tracking-wider py-6 px-20 text-sm font-semibold rounded-xs shadow-sm bg-evvy-blue focus:outline-none">
                  Let's Go →
                </button>
              </Link>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </LayoutWelcome>
  );
};

export default OrderTransferredConfirmation;
