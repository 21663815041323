import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Markdown from "markdown-to-jsx";

import Modal from "../../../components/common/modal";

import { indexColor } from "../../../utils/colors";

import { eventNames } from "../../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../../utils/analytics/helpers";
import {
  PRIVACY_POLICY,
  TELEHEALTH_INFORMED_CONSENT,
  TERMS_OF_SERVICE,
} from "../../constants";

const INTRO_COPY =
  "Before continuing to take your sample, please make sure that the following are true:";
const BULLETS = [
  "You’re not currently on your period or spotting",
  "You haven’t had sex in the past 24 hours",
  "You haven’t used any vulvar topical creams or gels for 24 hours (e.g. HRT, steriod cream)",
  "You haven’t used any vaginally-inserted treatments in the last 48 hours (e.g. suppositories, creams, gels, etc.)",
  "You haven't used antibiotics in the last 7 days",
];
const LDT_DISCLAIMER =
  "You do not currently have a high fever (>101.9F/38.8C), shortness of breath, difficulty breathing, chest pain, heart palpitations, abnormal bruising, abnormal bleeding, extreme fatigue, dizziness, new weakness or paralysis, difficulty with speech, confusion, suicidal thoughts, extreme pain in any body part, or inability to remain hydrated or keep down fluid. If you are experiencing any of these, please seek immediate medical attention or call 911.";

const TEST_LATER_COPY_MD =
  "If any of the above are true, please take the test at another time. No worries — we'll keep your test activated in *My Tests* whenever you're ready.";

const TestVerification: React.FC<{
  healthContext?: any;
  createHealthContext: (
    hash?: string,
    success?: () => void,
    failure?: () => void
  ) => void;
  test: { hash: string; test_order: number };
  ldtEnabled: boolean;
  recordConsent: (
    hash?: string,
    success?: () => void,
    failure?: () => void
  ) => void;
}> = ({
  healthContext,
  createHealthContext,
  test,
  ldtEnabled,
  recordConsent,
}) => {
  const [laterModalOpen, setLaterModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // route params
  const { testHash: hash } = useParams();
  const navigate = useNavigate();

  const toGoHealthContext = () => {
    var nextPage = `/tests/${hash}/context/basics/`;
    nextPage = `/tests/${hash}/context/personal-information/`;
    // user clicked continue. if health context exists, go for it. otherwise create then go
    if (healthContext) {
      // record consent timestamp
      recordConsent(hash);
      navigate(nextPage);
      window.scrollTo(0, 0);
    } else {
      setLoading(true);
      createHealthContext(
        hash,
        () => {
          // record consent timestamp
          recordConsent(hash);
          navigate(nextPage);
          window.scrollTo(0, 0);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    }
  };

  return (
    <div className="block w-full">
      <Helmet>
        <title>Test | Context | Verify</title>
      </Helmet>
      <div className="font-medium w-full mx-auto block">
        <div className="max-w-md">
          <b>{INTRO_COPY}</b>
          <br />
          <br />
          <ul className="list-none ml-0 flex-1">
            {BULLETS.map((bullet, i) => (
              <li key={i} className="flex">
                <span className={`text-${indexColor(i)} mr-1`}>• </span>
                {bullet}
              </li>
            ))}
            <li className="flex">
              <span className={`text-${indexColor(5)} mr-1`}>• </span>
              {LDT_DISCLAIMER}
            </li>
          </ul>
          <Markdown>{TEST_LATER_COPY_MD}</Markdown>
          <br />
          <br />
        </div>
      </div>

      <div className="border-t border-dashed mt-10 pt-10 pb-5 flex w-full">
        <div className="flex-1 mb-4 sm:mb-0 sm:mr-8">
          <div className="py-6 text-left">
            <Link
              to={"/tests/"}
              className="uppercase underline inline-block font-semibold tracking-wider text-sm cursor-pointer"
            >
              ← I'll Test Later
            </Link>
          </div>
        </div>
        <div
          className="flex-1 cursor-pointer"
          onClick={analyticsClickHandler({
            eventName: eventNames.HEALTH_HISTORY_FORM_ACCEPT_SAMPLE_CRITERIA,
            eventArgs: {
              testOrder: test.test_order,
              testHash: test.hash,
              version: 1,
            },
            clickHandler: toGoHealthContext,
          })}
        >
          <button
            disabled={loading}
            className="tracking-wider py-6 px-10 font-semibold rounded-xs shadow-sm uppercase bg-evvy-blue focus:outline-none"
          >
            I'm Ready →
          </button>
        </div>
        <div className="flex-1 hidden"></div>
      </div>

      <div className="text-left italic text-sm max-w-md mb-8">
        By clicking the above button, you agree to our{" "}
        <a
          href={PRIVACY_POLICY}
          target="_blank"
          className="hover:underline persistSize"
        >
          privacy policy
        </a>
        ,{" "}
        <a
          href={TERMS_OF_SERVICE}
          target="_blank"
          className="hover:underline persistSize"
        >
          terms of service
        </a>{" "}
        and{" "}
        <a
          href={TELEHEALTH_INFORMED_CONSENT}
          target="_blank"
          className="hover:underline persistSize"
        >
          telehealth informed consent
        </a>
        .
      </div>

      {laterModalOpen ? (
        <Modal closeModal={() => setLaterModalOpen(false)}>
          <div className="block md:flex">
            <div
              className="flex-1 bg-image-placeholder"
              style={{ minHeight: "200px" }}
            ></div>
            <div className="flex-1 bg-evvy-cream flex flex-col">
              <div className="p-8 md:p-16">
                <h2>Come back later to take your test.</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                  aliquam erat volutpat veniam.
                </p>
              </div>
              {/*<div className="p-8 md:px-16">
                <span>Want to know more? </span>
                <span className="uppercase underline font-semibold tracking-wider">Learn why this matters</span> →
              </div>*/}
              <Link to="/tests/">
                <button className="cursor-pointer w-full tracking-wider py-6 px-20 font-semibold rounded-xs shadow-sm bg-evvy-blue focus:outline-none">
                  Back to my tests
                </button>
              </Link>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
};

export default TestVerification;
